<template>
  <div>
    <div class="contain1" @click="showtype = false">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="待确认" name="first"></el-tab-pane>
        <el-tab-pane label="已确认" name="second">
          <div style="position: relative">
            <div class="searchBox" ref="viewBox">
              <el-form class="searchBoxForm" :inline="true" size="small">
                <el-form-item label="甲方/联系电话">
                  <el-input
                    v-model="confirmSearchForm.firstPartyOrPhone"
                    placeholder="请输入"
                    clearable
                    style="width: 150px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="合同确认状态" label-width="110px">
                  <el-select
                    v-model="confirmSearchForm.contractConfirmStatus"
                    placeholder="请选择"
                    style="width: 150px"
                    clearable
                  >
                    <el-option
                      v-for="item in confirmStatusDic"
                      :key="item.code"
                      :label="item.label"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <div ref="viewContract">
                  <el-form-item
                    label="合同类型"
                    class="searchItem viewContract"
                    label-width="80px"
                  >
                    <el-input
                      @click.native.stop="showtype = true"
                      v-model="pageParams.contractType"
                      placeholder="请选择"
                      class="SearchInput"
                      clearable
                      @clear="clearType"
                      readonly
                    >
                      <template v-slot:suffix @click.stop="clearType">
                        <i
                          @click.stop="clearType"
                          :class="
                            pageParams.contractType
                              ? 'el-icon-circle-close'
                              : 'el-icon-caret-bottom'
                          "
                        ></i>
                      </template>
                    </el-input>
                    <div
                      class="searchType"
                      @click.stop="showtype = true"
                      v-if="showtype"
                      :style="style"
                    >
                      <div style="margin: 10px">
                        <el-radio-group
                          v-model="pageParams.contractTypeParent1"
                          @change="changeChildSearch"
                        >
                          <div v-for="item in options" :key="item.id">
                            <el-radio
                              class="radioList"
                              :label="item.id"
                              :key="item.id"
                              >{{ item.name }}</el-radio
                            >
                            <el-radio-group
                              v-model="pageParams.contractTypeParent2"
                              @change="changeChildSearch2"
                              style="margin-left: 20px"
                              :disabled="parentID1 != item.id ? true : false"
                              v-if="item.children && item.children.length > 0"
                            >
                              <div
                                v-for="itema in item.children"
                                :key="itema.id"
                              >
                                <el-radio
                                  class="radioList"
                                  :label="itema.id"
                                  :key="itema.id"
                                  >{{ itema.name }}</el-radio
                                >
                                <el-checkbox-group
                                  @change="changeLabelSearch"
                                  v-model="searchContractTypeChildIdArr"
                                  style="margin-left: 20px"
                                  :disabled="
                                    parentID2 != itema.id ? true : false
                                  "
                                  v-if="
                                    itema.children && itema.children.length > 0
                                  "
                                >
                                  <el-checkbox
                                    v-for="itemc in itema.children"
                                    :label="itemc.id"
                                    :key="itemc.id"
                                    style="width: 140px"
                                    >{{ itemc.name }}</el-checkbox
                                  >
                                </el-checkbox-group>
                              </div>
                            </el-radio-group>
                          </div>
                        </el-radio-group>
                      </div>
                    </div>
                  </el-form-item>
                </div>

                <el-form-item>
                  <template slot="label">
                    <el-select
                      v-model="confirmSearchForm.timeType"
                      style="width: 150px"
                    >
                      <el-option
                        v-for="item in searchLabelDic"
                        :key="item.code"
                        :label="item.label"
                        :value="item.code"
                      >
                      </el-option>
                    </el-select>
                  </template>
                  <el-date-picker
                    v-model="date"
                    type="datetimerange"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="onSetDate"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    size="small"
                    @click="handleCurrentChange(1)"
                    >查询</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-table
        :data="tableData"

        border
        :height="
          activeName == 'second'
            ? ' calc(100vh - 365px)'
            : 'calc(100vh - 315px)'
        "
        stripe
        :header-cell-style="{ background: '#f8f8f9' }"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="center"
          width="50"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in tableHeader"
          :key="index"
          :label="item.label"
          :prop="item.property"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.column.property === 'contractType'">
              {{ scope.row.contractTypeParent || "-" }}
              <span v-if="scope.row.contractTypeChild"
                >({{ scope.row.contractTypeChild }})</span
              >
            </div>
            <div v-else>
              {{ scope.row[scope.column.property] || "-" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="合同确认状态"
          align="center"
          width="100"
          v-if="activeName == 'second'"
        >
          <template slot-scope="{ row }">
            <el-tag
              :type="
                row.contractConfirmStatus == '待确认'
                  ? 'info'
                  : row.contractConfirmStatus == '确认中'
                  ? ''
                  : 'success'
              "
              >{{ row.contractConfirmStatus }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left" width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="onConfirm(scope.row)"
              v-if="activeName == 'first'"
              >去确认</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="onShowContractInfo(scope.row)"
              v-if="activeName !== 'first'"
              >查 看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="合同信息"
      :visible.sync="confirmDialog"
      :close-on-click-modal="false"
      width="35%"
    >
      <el-dialog
        title="工单信息"
        :visible.sync="orderVisible"
        append-to-body
        width="30%"
      >
        <div class="info-content">
          <el-row class="info-item">
            <span class="label">工单单号：</span>
            <span class="content-item">{{ orderInfo.orderCode }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">用户类型：</span>
            <span class="content-item">{{ orderInfo.userType }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">用气设备：</span>
            <span class="content-item">{{ orderInfo.equipment }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">执行位置：</span>
            <span class="content-item">{{ orderInfo.address }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">执行简图：</span>
            <span class="content-item">
              <el-image
                style="width: 100px; height: 100px"
                :src="orderInfo.firstImageUrl"
                :preview-src-list="orderInfo.imageUrl"
              >
              </el-image>
              <span v-if="orderVisible"
                >共{{ orderInfo.imageUrl.length }}张</span
              >
            </span>
          </el-row>
          <el-row class="info-item">
            <span class="label">测量员：</span>
            <span class="content-item">{{ orderInfo.realname }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">创建时间：</span>
            <span class="content-item">{{ orderInfo.createTime }}</span>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="orderVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
      <div class="contractInfo" v-if="confirmDialog">
        <el-row class="info-item">
          <el-col :span="8">
            <span class="label">合同来源：</span>
            <span class="content-item">{{
              contractInfo.contractFrom == "报装"
                ? "用户" + contractInfo.contractFrom
                : contractInfo.contractFrom
            }}</span>
          </el-col>
          <el-col :span="8" v-if="contractInfo.orderCode">
            <span class="label">报装单号：</span>
            <span class="content-item">{{ contractInfo.orderCode }}</span>
          </el-col>
          <el-col :span="8" v-if="contractInfo.orderCode">
            <span class="label">报装工单信息：</span>
            <!-- 改成按钮 -->
            <span class="content-item">
              <el-button
                style="padding: 0"
                type="text"
                @click="onShowOrderInfo(contractInfo.orderCode)"
                >查看信息 ></el-button
              >
            </span>
          </el-col>
        </el-row>
        <el-row class="info-item">
          <el-col :span="24">
            <span class="label">甲方：</span>
            <span class="content-item">{{ contractInfo.firstParty }}</span>
          </el-col>
        </el-row>
        <el-row class="info-item">
          <el-col :span="24">
            <span class="label">联系电话：</span>
            <span class="content-item">{{ contractInfo.phone }}</span>
          </el-col>
        </el-row>
        <el-row class="info-item">
          <el-col :span="24">
            <span class="label">联系地址：</span>
            <span class="content-item">{{ contractInfo.address }}</span>
          </el-col>
        </el-row>
        <el-row class="info-item">
          <el-col :span="24">
            <span class="label">合同类型：</span>
            <span class="content-item">
              {{ contractInfo.contractTypeParent || "-" }}
              <span v-if="contractInfo.contractTypeChild"
                >({{ contractInfo.contractTypeChild }})</span
              >
            </span>
          </el-col>
        </el-row>
        <el-row class="info-item">
          <el-col :span="24">
            <span class="label">合同编号：</span>
            <span class="content-item">{{ contractInfo.contractNumber }}</span>
          </el-col>
        </el-row>
        <el-row class="info-item">
          <el-col :span="24">
            <span class="label">合同附件：</span>
            <span class="content-item">
              <el-image
                v-if="contractInfo.fileType == 1"
                style="width: 100px; height: 100px"
                :src="firstImg"
                @click="exportExcel(contractInfo)"
              >
              </el-image>
              <el-image
                v-else
                :src="firstImg"
                style="width: 60px; height: 60px"
                :preview-src-list="contractInfo.contractAttachment"
              >
              </el-image>
              <span>共{{ contractInfo.contractAttachment.length }}项</span>
            </span>
          </el-col>
        </el-row>
        <el-row class="info-item">
          <el-col :span="24">
            <span class="label">缴费佐证：</span>
            <span class="content-item">
              <el-image
                :src="firstImgPayPoof"
                style="width: 60px; height: 60px"
                :preview-src-list="contractInfo.payProof"
              >
              </el-image>
              <span>共{{ contractInfo.payProof.length }}项</span>
            </span>
          </el-col>
        </el-row>
        <el-row class="info-item">
          <el-col :span="24">
            <span class="label">签约说明：</span>
            <span class="content-item">{{ contractInfo.signDescription }}</span>
          </el-col>
        </el-row>
        <el-row class="info-item">
          <el-col :span="12">
            <span class="label">签约人：</span>
            <span class="content-item">{{ contractInfo.signRealname }}</span>
          </el-col>
          <el-col :span="12">
            <span class="label">签约时间：</span>
            <span class="content-item">{{ contractInfo.signTime }}</span>
          </el-col>
        </el-row>
        <el-row v-if="!isConfirm">
          <el-col :span="12">
            <span class="label">存档确认人：</span>
            <span class="content-item">{{
              contractInfo.restoreConfirmRealname
            }}</span>
          </el-col>
          <el-col :span="12">
            <span class="label">存档确认时间：</span>
            <span class="content-item">{{
              contractInfo.restoreConfirmTime
            }}</span>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer" v-if="isConfirm">
        <el-button @click="confirmDialog = false">取 消</el-button>
        <el-button type="primary" @click="secondConfirmation = true"
          >确 认</el-button
        >
      </span>
      <span slot="footer" class="dialog-footer" v-else>
        <el-button @click="confirmDialog = false">关 闭</el-button>
      </span>
      <el-dialog
        title="存档确认"
        :visible.sync="secondConfirmation"
        :close-on-click-modal="false"
        append-to-body
        width="30%"
        @close="closeDialog('ruleForm')"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="档案号" prop="restoreFileNumber">
            <el-input v-model="ruleForm.restoreFileNumber"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="secondConfirmation = false">取消</el-button>
          <el-button type="primary" @click="onConfirmInfo('ruleForm')"
            >确 认</el-button
          >
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import pageMixins from "@/utils/pageMixins";
import axios from "@/apis/contractApi";
import { getDict, selectDictListByParentCode } from "@/apis/commonType";
export default {
  mixins: [pageMixins],
  data() {
    return {
      activeName: "first", //  tab选择
      tableHeader: [], //  表头数据
      pendingTableHeader: [
        { label: "合同来源", property: "contractFrom" },
        { label: "合同编号", property: "contractNumber" },
        { label: "合同类型", property: "contractType" },
        { label: "甲方", property: "firstParty" },
        { label: "联系电话", property: "phone" },
        { label: "地址", property: "address" },
        { label: "签约人", property: "signRealname" },
        { label: "签约时间", property: "signTime" },
      ], //  待确认表格表头
      confirmTableHeader: [
        { label: "存档确认人", property: "restoreConfirmRealname" },
        { label: "存档确认时间", property: "restoreConfirmTime" },
        { label: "档案号", property: "restoreFileNumber" },
      ], //  确认表格表头
      tableData: [], //  表格数据
      pageParams: {
        current: 1,
        size: 100,
        confirmType: 3,
        confirmStatus: 1,
        contractType: null,
        contractTypeParent2: null,
        contractTypeParent1: null,
      },
      confirmSearchForm: {
        contractConfirmStatus: null,
        contractType: null,
        archivesNum: null,
        timeType: 1,
        startTime: null,
        endTime: null,
      }, //  确认搜索表单
      date: [], //  选择时间区间
      confirmStatusDic: [
        // { label: "待确认", code: 1 },
        { label: "确认中", code: 2 },
        { label: "已确认", code: 3 },
      ], //  确认状态字典
      contractTypeDic: [], //  合同类型字典
      searchLabel: 1, //  搜索选择
      searchLabelDic: [
        {
          code: 1,
          label: "确认时间",
        },
        {
          code: 2,
          label: "签约时间",
        },
      ],
      firstImg: "", //  合同附件第一张图片
      firstImgPayPoof: "", //  缴费佐证第一张图片
      isConfirm: false, //  是否确认操作
      confirmDialog: false, //  确认弹窗开关
      secondConfirmation: false, //  二次确认弹窗开关
      contractInfo: {}, //  合同详情
      isShowList: false, //  大图预览开关
      ruleForm: {
        restoreFileNumber: "",
      },
      rules: {
        restoreFileNumber: [
          { required: true, message: "请填写档案编号", trigger: "blur" },
        ],
      },

      options: [],
      showtype: false,
      searchContractTypeChildIdArr: [],
      isCheckBokS: true,
      parentID1: null,
      parentID2: null,
      parentIndex: 0,
      parentName: "",
      parentName2: "",
      orderInfo: {},
      orderVisible: false,
      dom: null,
      style: {
        left: 0,
      },
      left: 0,
    };
  },
  mounted() {
    this.handleClick();
  },
  beforeDestroy() {
    if (this.dom) this.dom.removeEventListener("scroll", this.handleFun);
  },
  methods: {
    handleFun() {
      
      this.style.left = this.left - this.dom.scrollLeft + "px";
    },
    getContractLeft() {
      this.left = this.$refs.viewContract.offsetLeft + 80;
      this.style.left = this.left + "px";
    },
    closeDialog(form) {
      this.ruleForm.restoreFileNumber = "";
      this.$refs[form].resetFields();
    },
    /**
     * 选择的tab
     */
    handleClick() {
      if (this.activeName == "second") {
        setTimeout(() => {
          this.getContractLeft();
          this.dom = this.$refs.viewBox;
          this.dom.addEventListener("scroll", this.handleFun);
        }, 500);
      } else {
        if (this.dom) this.dom.removeEventListener("scroll", this.handleFun);
        this.dom = null;
      }
      let { activeName, pendingTableHeader, confirmTableHeader } = this;
      if (activeName === "first") {
        this.pageParams.confirmStatus = 1;
        this.tableHeader = [...pendingTableHeader];
      } else {
        this.pageParams.confirmStatus = 2;
        this.tableHeader = [...pendingTableHeader, ...confirmTableHeader];
        //  获取合同类型级联字典
        this.getOption();
      }
      this.handleCurrentChange(1);
    },
    getOption() {
      selectDictListByParentCode({
        parentCode: "hetongleixing",
        level: 4,
      }).then((res) => {
        if (res.code == 200) {
          this.options = res.data;
        }
      });
    },
    clearType() {
      if (this.pageParams.contractType) {
        this.pageParams.contractTypeParent1 = "";
        this.pageParams.contractTypeParent2 = null;
        this.searchContractTypeChildIdArr = [];
        this.pageParams.contractTypeChild = "";
        this.isCheckBokS = true;
        this.parentID2 = null;
        this.parentID1 = null;
        this.pageParams.contractType = "";
        this.showtype = false;
      }
    },
    changeChildSearch(val) {
      this.parentID1 = val;
      this.parentID2 = null;
      this.searchContractTypeChildIdArr = [];
      this.pageParams.contractTypeParent2 = null;
      this.options.forEach((el, index) => {
        if (el.id == val) {
          this.pageParams.contractType = el.name;
          this.parentIndex = index;
          this.parentName = el.name;
        }
      });
    },
    changeChildSearch2(val) {
      let { parentIndex } = this;
      this.parentID2 = val;
      this.searchContractTypeChildIdArr = [];
      this.options[parentIndex].children.forEach((el) => {
        if (el.id == val) {
          this.pageParams.contractType = this.parentName + "-" + el.name;
          this.parentName2 = this.pageParams.contractType;
        }
      });
    },
    changeLabelSearch(val) {
      let { parentIndex } = this;
      var namekey = {};
      this.options[parentIndex].children.forEach((el) => {
        if (el.id == this.pageParams.contractTypeParent2) {
          el.children.forEach((item) => {
            namekey[item.id] = item.name;
          });
          var name = [];
          val.forEach((items) => {
            name.push(namekey[items]);
          });
          let showname = name.toString();
          if (showname) {
            this.pageParams.contractType =
              this.parentName2 + "(" + showname + ")";
          }
        }
      });
    },
    /**
     * 加载表格数据
     */
    async loadList() {
      this.pageParams.contractTypeChild = "";
      if (this.searchContractTypeChildIdArr.length > 0) {
        this.pageParams.contractTypeChild =
          this.searchContractTypeChildIdArr.toString();
      }
      this.pageParams.contractTypeParent = this.pageParams.contractTypeParent1;
      if (this.pageParams.contractTypeParent2) {
        this.pageParams.contractTypeParent +=
          "," + this.pageParams.contractTypeParent2;
      }
      let { pageParams, activeName, confirmSearchForm } = this;
      let data = JSON.parse(JSON.stringify(pageParams));
      if (activeName == "second") data = Object.assign(data, confirmSearchForm);
      try {
        let res = await axios.getContractConfirmList(data);
        res.data.records.forEach((el) => {
          if (el.contractFrom === "报装") el.contractFrom = "用户报装";
        });
        this.tableData = res.data.records;
        this.total = res.data.total;
      } catch (e) {
        this.tableData = [];
      }
    },
    //  查询工单信息
    async onShowOrderInfo(orderCode) {
      try {
        let res = await axios.getOrderInfo({ orderCode });
        res.data.imageUrl = res.data.imageUrl.split(",");
        res.data["firstImageUrl"] = res.data.imageUrl.length
          ? res.data.imageUrl[0]
          : "";
        this.orderInfo = res.data;
        this.orderVisible = true;
      } catch (e) {}
    },
    /**
     * 去确认
     */
    async onConfirm(row) {
      let { id: contractId } = row;
      this.isConfirm = true;
      try {
        let res = await axios.getContractInfo({ contractId });
        res.data.contractAttachment = res.data.contractAttachment.split(",");
        res.data.payProof = res.data.payProof.split(",");
        this.contractInfo = res.data;
        //  判断类型
        let reg = /\.(pdf|PDF)$/;
        this.firstImg = reg.test(res.data.contractAttachment[0])
          ? require("@/assets/image/pdf.jpg")
          : res.data.contractAttachment[0];
        let fileType = reg.test(res.data.contractAttachment[0]) ? 1 : 0;
        this.$set(this.contractInfo, "fileType", fileType);
        this.firstImgPayPoof = res.data.payProof[0];
        this.confirmDialog = true;
      } catch (e) {}
    },
    /**
     * 展示图片列表
     */
    onShowImg(list) {
      this.isShowList = true;
      this.showImgList = [...list];
    },
    /**
     * 存档确认
     */
    onConfirmInfo(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) return false;
        let data = {
          confirmType: 3,
          contractId: this.contractInfo.id,
          restoreFileNumber: this.ruleForm.restoreFileNumber,
        };
        try {
          axios.confirmContract(data);
          this.$message.success("存档确认成功");
          this.secondConfirmation = false;
          this.confirmDialog = false;
          setTimeout((el) => {
            this.loadList();
          }, 500);
        } catch (e) {}
      });
    },
    /**查看合同详情 */
    async onShowContractInfo(row) {
      let { id: contractId } = row;
      this.isConfirm = false;
      try {
        let res = await axios.getContractInfo({ contractId });
        res.data.contractAttachment = res.data.contractAttachment.split(",");
        res.data.payProof = res.data.payProof.split(",");
        this.contractInfo = res.data;
        //  判断类型
        let reg = /\.(pdf|PDF)$/;
        this.firstImg = reg.test(res.data.contractAttachment[0])
          ? require("@/assets/image/pdf.jpg")
          : res.data.contractAttachment[0];
        let fileType = reg.test(res.data.contractAttachment[0]) ? 1 : 0;
        this.$set(this.contractInfo, "fileType", fileType);
        this.firstImgPayPoof = res.data.payProof[0];
        this.confirmDialog = true;
      } catch (e) {}
    },
    /**设置日期 */
    onSetDate() {
      let { date } = this;
      if (date) {
        this.confirmSearchForm.startTime = date[0];
        this.confirmSearchForm.endTime = date[1];
      } else {
        this.confirmSearchForm.startTime = null;
        this.confirmSearchForm.endTime = null;
      }
    },
    exportExcel(data) {
      data.contractAttachment.forEach((el, index) => {
        let x = new XMLHttpRequest();
        x.open("GET", el, true);
        x.responseType = "blob";
        x.onload = function (e) {
          let url = window.URL.createObjectURL(x.response);
          let a = document.createElement("a");
          a.href = url;
          a.download = data.contractNumber + "附件" + index + 1; //下载后的文件名
          a.click();
        };
        x.send();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.contain1 {
  position: relative;
}
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .searchItem {
      // margin-bottom: 0px;
    }
  }
}
.info-item {
  margin-bottom: 20px;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}

::v-deep .el-tabs__content {
  overflow: inherit;
}
.searchType {
  width: 210px;
  box-shadow: 1px 1px 5px #888888;
  position: absolute;
  z-index: 100;
  background-color: #fff;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchInput {
  width: 100%;
}
.radioList {
  display: block;
  margin: 10px 0px;
}
::v-deep.viewContract .el-form-item__content {
  position: initial;
}
</style>